// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,
    Stack,
    DialogContentText,    
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    IconButton,
    Collapse,
    Alert,
    TextField,
    Grid,
    Typography,
    Box,
    CircularProgress
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Refresh as RefreshIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    Close as CloseIcon,
    Save as SaveIcon,
    ParaglidingSharp,
    Visibility as ShowIcon,
    Delete as RemoveIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { DataGrid } from '@mui/x-data-grid'
import { API_URL_SIMPLECRM } from './../../../../components/common'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
    SimpleUIListview,
    SimpleUIDrawer,
    SimpleUIDialogConfirm,
    SimpleUIDialogListSelector,
    
    SimpleUICommonInterpolate
} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// import RenderString from './renderString'
// import RenderStringFormat from './renderStringFormat'
// import RenderTextField from './renderTextField'
import Render from '../render'
import Dialogg from '../dialog'
import { interpolate } from '../../../../simpleUI/components/common';
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

// ----------------------------------------------------------------------


export default (props) => {
    const [formData, setFormData] = useState(undefined)
    const {user, accessToken} = SimpleUIAuthState()    

    const [dataGridColumns, setDataGridColumns] = useState([])
    const [dataGridRows, setDataGridRows] = useState([])
    const [dataGridSelectionModel, setDataGridSelectionModel] = useState([]) 

    const [dialogCreate, setDialogCreate] = useState(false)
    const [dialogEdit, setDialogEdit] = useState(false)

    const [dialogStateDelete, setDialogDelete] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })


    // const Buttons = (props) => {
        

    //     console.log (formData)
    //     const click = () => {
    //         console.log (props)

    //     }
    //     return (
    //         <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                    
    //             <IconButton aria-label="edit" size="small">
    //                 <EditIcon fontSize="inherit"/>
    //             </IconButton>
                    
    //             <IconButton aria-label="delete" size="small" onClick={click}>
    //                 <RemoveIcon fontSize="inherit"/>
    //             </IconButton>
    //         </Stack>
    //     )

    // }


    // console.log (props)

    useEffect(() => {        
        
        const asyncFunction = async (data) => {

            console.log ("---")
            const entities = []
            for (const iterator of (data || [])) {
                console.log (iterator)
                if (Object.prototype.toString.call(iterator) !== "[object String]") {   // Only Child entitites.
                    if (props.type.fields[props.fieldId].config.allowedTypes.find(typeId => typeId === iterator.typeId))
                        entities.push(structuredClone(iterator))
                }
            }

            for (const iterator of props.type.fields[props.fieldId].config.allowedTypes) {
                if (props.type.fields[props.fieldId].config.refs[props.type.fields[props.fieldId].config.allowedTypes])
                    entities.push(...(await getEntitiesByRef(iterator, `${props.type.fields[props.fieldId].config.refs[props.type.fields[props.fieldId].config.allowedTypes]}:${props.entityId}`)))

                    // entities.push((await getEntitiesByRef(iterator, `${props.type.fields[props.fieldId].config.refs[props.type.fields[props.fieldId].config.allowedTypes]}:${props.entityId}`)))
            }

            

            setFormData(entities)
        }                

        asyncFunction(props.data[props.fieldId])
    }, [])


    const getEntitiesByRef = async (entityTypeId, ref) => {
        let output = []    
        try {
            let getFetch = await fetch(`${API_URL_SIMPLECRM.ENTITIES}?typeId=${entityTypeId}&ref=${ref}` , {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getFetch.ok)
                throw new Error((await getFetch.json()).error.code)

            output = await getFetch.json()

            // console.log (formData)

            // const index = output.findIndex(i => i.id === type.id)
            // if (index > -1)
            //     output.splice(index, 1)

        } catch (error) {    
            // console.log (error)        
            // onError(error)
        }  

        // console.log (output)

        return output
    }

    useEffect(() => {
        if (formData == undefined)
            return

        // console.log (formData)

        // const asyncFetch = async () => {
        //     try {
        //         let getFetch = await fetch(API_URL_SIMPLECRM.ENTITIES +"?parentId="+props.entityId+"_"+props.fieldId, {
        //             method: 'GET',
        //             headers: { 
        //                 'Content-Type': 'application/json',
        //                 'Authorization': "Bearer "+ accessToken
        //             }            
        //         })
                
        //         if (!getFetch.ok)
        //             throw new Error((await getFetch.json()).error.code)

        //         setDataGridRows((await getFetch.json()))
        //     } catch (error) {            
        //         console.log (error)
        //         // handleError(error)
        //     }   
        // }
        
        // asyncFetch()

        let columns = [
            { field: 'id', headerName: 'Id', flex: 1.0 },        
            { field: 'typeId', headerName: 'Type', flex: 0.5,
                renderCell: (params) => {                
                    // try {return types.find(o => o.id === params.value).name} catch (error) {return "Unknown"}
                }
            }              
        ]
        

        if (props.type?.layoutsD[`field_list_${props.fieldId}`]) {
            
            columns = []
            for (const column of props.type?.layoutsD[`field_list_${props.fieldId}`].content.columns) {
                // console.log (column)
                if (column.type === "data") {
                    columns.push({
                        headerName: column.headerName || "",
                        field: column.field,
                        flex: column.flex,
                        renderCell: (params) => {
                            // console.log (params.row)
                            return (params.row[column.field])
                        }
                    })
                }                    
            }
            
        }

        columns.push ({ field: 'buttons', headerName: '', flex: 1.0, sortable: false, align: "right",
            renderCell: (params) => {
                const { row } = params
                const edit = () => {
                    handleEdit(row.id)
                }

                const remove = () => {
                    handleDelete(row.id)
                }

                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                    
                        <IconButton aria-label="edit" size="small" onClick={edit}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>
                            
                        <IconButton aria-label="delete" size="small" onClick={remove} disabled={row.hasRef}>
                            <RemoveIcon fontSize="inherit"/>
                        </IconButton>
                    </Stack>
                )
            }           
        })

        // console.log (columns)

        setDataGridColumns(columns)

        let e = []
        for (const iterator of formData) {
            if (iterator.data)
                e.push({id: iterator.id, typeId: iterator.typeId, ...iterator.data})
            else
                e.push(iterator)
        }
        // console.log (e)
        setDataGridRows(e)

        handleOnChange(formData) 
    }, [formData])

    const handleOnChange = (value) => {
        // console.log (value)
        if (props.onChange)
            props.onChange({target: {name: props.fieldId, value: value}})
    }

    const listviewColumnsAPICredentials = [
        { field: 'title', headerName: 'Dato', flex: 0.3 },
        { field: 'text', headerName: 'Tekst', flex: 1.0 },                 
        { field: 'type', headerName: 'Beløb' },
        { field: 'id', headerName: '', storable: false,
            renderCell: params => {                
                return (
                    <Stack direction="row" alignItems="right" spacing={1}>
                        {/* <ShowIcon aria-label="edit" size="small" onClick={() => {handleOnAPICredentialEdit(params.value)}}>
                            <EditIcon fontSize="inherit"/>
                        </ShowIcon> */}
                                          
                    </Stack>
                )
            }           
        },        
    ]   

    const handleOnClickAwayAPICredentials = () => {

    }

    const handleAdd = (entityTypeId) => {
        setDialogEdit({
            open: true,
            title: "",
            children: (<Render typeId={entityTypeId} entity={null} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}></Render>)
        })

        
    }

    const handleEdit = (entityId) => {

        // console.log (props.data[props.fieldId].find(i => i === entityId))

        
        // console.log (props.data[props.fieldId].find(e => e.id === entityId))
        // return
        if (props.data[props.fieldId].find(i => i === entityId)) {
            setDialogEdit({
                open: true,
                title: "",
                children: (<Render entityId={entityId} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}></Render>)
            })
        } else if (props.data[props.fieldId].find(e => e.id === entityId)) {            
            setDialogEdit({
                open: true,
                title: "",
                children: (<Render entity={formData.find((e) => e.id === entityId)} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}></Render>)
            })
        }


        // formData.find((e) => e.id === entityId)

        // if (Object.prototype.toString.call() === "[object String]") {

        // entity={formData.find((e) => e.id === entityId)}

        
    }

    const handleDelete = (id) => {
        
        setDialogDelete({
            open: true,
            title: "Delete entity",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this item?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {

                // console.log()
// 
                const newdata = structuredClone(formData)
                

                newdata.splice(newdata.findIndex((e) => e.id === id), 1)

                setFormData(structuredClone(newdata))

                // try {
                //     let entityDelete = await fetch(API_URL_SIMPLECRM.ENTITIES + id, {
                //         method: 'DELETE', 
                //         headers: {
                //             'Content-Type': 'application/json',
                //             'Authorization': "Bearer "+ accessToken
                //         }
                //     })  
        
                //     if (!entityDelete.ok)
                //         throw new Error((await entityDelete.json()).error.code)
        
                //     updateCallback()
                // } catch (error) {
                //     console.log (error)
                //     // handleError(error)
                // }                
            },
            onDecline: () => {}
        })
    }

    const createCallback = (type, entity) => {
        // console.log (entity)
        const updatedFormData = (formData) ? structuredClone(formData) : []
        // console.log (entity)
        updatedFormData.push(entity)
        // console.log (updatedFormData)
        setFormData(structuredClone(updatedFormData))
    }

    const updateCallback = (entity, type) => {
        const updatedFormData = (formData) ? structuredClone(formData) : []
        updatedFormData[formData.findIndex(e => e.id === entity.id)] = entity
        setFormData(structuredClone(updatedFormData))
    }

    const returnCallback = () => {        
        setDialogCreate(false)
        setDialogEdit(false)
    }

    function getRowId(row) {
        // console.log ("---------")
        // console.log (row)
        // console.log ("---------")
        return row.id
      }

    // console.log (props.type.fields[props.fieldId].config.allowedTypes[0])

    const handleDataGridOnRowSelectionModelChange = (selection) => {
        setDataGridSelectionModel(selection)            
        // if (selection.length != 0)
        //     setToolbarState({ ...toolbarState, delete: true, edit: true })
        // else
        //     setToolbarState({ ...toolbarState, delete: false, edit: false })
    }

    // console.log (props.entityId)

    return (
        <React.Fragment>
                        <SimpleUIDialogConfirm dialogState={dialogStateDelete} setDialogState={setDialogDelete}/>



            <Dialogg fullWidth={true} maxWidth={'md'} dialogState={dialogEdit} setDialogState={setDialogEdit}></Dialogg>

            <DataGrid            
                autoHeight                  
                disableColumnMenu
                columns={dataGridColumns} 
                rows={dataGridRows}
                onRowSelectionModelChange={handleDataGridOnRowSelectionModelChange}
                rowsPerPageOptions={[100]} 
                selectionModel={dataGridSelectionModel}      
                getRowId={getRowId}
                // getRowId={(row) => row.id} 
            />
            <Stack spacing={2} direction="row" justifyContent="start" sx={{ pb: '20px' }}>
                
                {Array.isArray(props?.type?.layoutsD[`field_list_${props.fieldId}`]?.content?.buttons) &&
                    <React.Fragment>
                        {props.type.layoutsD[`field_list_${props.fieldId}`].content.buttons.map(function (item, i)  {
                            return (
                                <Button onClick={() => {handleAdd(item.typeId)}}>{item.label}</Button>        
                            )
                        })}  
                    </React.Fragment>
                }
                
            </Stack>
            
        </React.Fragment>
    )
}