// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import { useNavigate, useBlocker } from "react-router-dom"
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Paper,
    AppBar,
    Stack,
    DialogContentText,    
    Button,
    IconButton,
    Collapse,
    Alert,
    TextField,
    // Grid,
    Typography,
    Box,
    CircularProgress,
    Tab,
    Tabs,
    TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,  
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
import { DataGrid } from '@mui/x-data-grid'
import Grid from '@mui/material/Unstable_Grid2'

import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Edit as EditIcon,
    Close as CloseIcon,
    Delete as RemoveIcon,
    CarCrash, 
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAppState,
    SimpleUIUseAppDispatch,
    SimpleUIAppUpdateRoutes,    
    SimpleUIAuthState,
    SimpleUIDrawer,
    SimpleUIDialogConfirm
} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import {API_URL_SIMPLECRM, setStateKeyValuePair} from '../../../components/common'
import FormField from './field/'
import FormLayout from './layout/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

const sxPaper = {p: 2, m: 2, mb: 0}

const DEFAULT_VALUE_TYPE = {
    name: "",
    navigation: {url: "", label: "", sort: 0, icon: ""},
    fields: {},
    layouts: []
}

function CustomTabPanel(props) {
    const { children, value, index, sx, ...other } = props;
  
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={sx}
            {...other}
        >   
        {/* <Box sx={sx} style={{ display: value === index ? 'block': 'none'}}>   */}
            {children}
        {/* </Box> */}
      </Box>
    )
}

export default (props) => {
    const params = useParams()
    const navigate = useNavigate()    
    
    const appDispatch = SimpleUIUseAppDispatch()
    const { layouts, settings } = SimpleUIAppState()    
    const { user, accessToken, authorized } = SimpleUIAuthState()
    
    // #region FORM
    const formDefaultState = {
        isInitializing: true,

        mainButtonDisabled: true,
        mainButtonLoading: false,

        purgeButtonLoading: false,
        renderButtonLoading: false,

        disabled: false,
        dirty: false,
        valid: false,

        reload: false,

        error: false,
        errorText: "",
    }

    const [ formState, setFormState ] = useState(formDefaultState)    
    const [ formDefaultData, setFormDefaultData ] = useState()
    const [ formData, setFormData ] = useState()
    const [ formRefresh, setFormRefresh ] = useState(false)

    const [ entityTypes, setEntityTypes ] = useState([])

    const [ stats, setStats ] = useState({})

    const [ dialogState, setDialog ] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })

    useEffect(async () => {
        setEntityTypes(await getEntityTypes())
        setStats(await getStats())

        if (params.typeId == "00000000-0000-0000-0000-000000000000") {
            setFormDefaultData(DEFAULT_VALUE_TYPE)
            return
        } 

        try {
            let fetchGet = await fetch(`${API_URL_SIMPLECRM.TYPES}${params.typeId}`, {
            method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!fetchGet.ok)
                throw new Error((await fetchGet.json()).error.code)

            setFormDefaultData(structuredClone((await fetchGet.json())))
        } catch (error) {
            handleError(error)
        }                    
    }, [])

    useEffect(() => {
        if (!formDefaultData)
            return

        setFormData(structuredClone(formDefaultData))

    }, [formDefaultData])

    useEffect(() => {
        if (!formData)
            return

        let dirty = true
        let formValid = 0

        // Validate: CHANGED
        if (JSON.stringify({...formDefaultData}) === JSON.stringify({ ...formData})) {
            dirty = false
            formValid++
        }

        // Validate: NAME
        if (formData.name == "") {
            formValid++
        }

        // Update: FIELDS
        if (formData.fields) {
            var layoutCount = 0
            for (var k in formData.fields) if (formData.fields.hasOwnProperty(k)) ++layoutCount

            if (
                (layoutCount != dataGridRowsLayouts.length) ||
                (JSON.stringify(structuredClone(formDefaultData.fields)) != JSON.stringify(structuredClone(formData.fields)))
            ) {      
                let datagrid = []                
                Object.keys(formData.fields).forEach(function(key) {
                    let row = structuredClone(formData.fields[key])

                    if (row.type == "textfield") 
                        row.options = `${row.subType}`

                    if (row.type == "upload") 
                        row.options = `${row.subType}`

                    if (row.type == "user") 
                        row.options = `${formData.fields[row.dataSource.name].label}, ${formData.fields[row.dataSource.email].label}`
                
                    datagrid.push({id: key, ...row})
                })          
                setDataGridRowsFields(datagrid)
            }
        }

        // Update: LAYOUTS
        if (formData.layouts) {
            // console.log (formData.layouts)
            // var layoutCount = 0
            // for (var k in formData.layouts) if (formData.layouts.hasOwnProperty(k)) ++layoutCount

            if (
                (formData.layouts.count != dataGridRowsLayouts.length) ||
                (JSON.stringify(structuredClone(formDefaultData.layouts)) != JSON.stringify(structuredClone(formData.layouts)))
            ) {      
                let datagrid = []                
                // Object.keys(formData.layouts).forEach(function(key) {
                for (const layout of formData.layouts) {
                    // let row = structuredClone(layout)
                    // console.log(row)

                    // if (row.type == "textfield") 
                    //     row.options = `${row.subType}`

                    // if (row.type == "upload") 
                    //     row.options = `${row.subType}`

                    // if (row.type == "user") 
                    //     row.options = `${formData.fields[row.dataSource.name].label}, ${formData.fields[row.dataSource.email].label}`
                
                    datagrid.push(layout)
                }          
                setDataGridRowsLayouts(datagrid)
            }
        }
        
        // console.log (formValid)
        // console.log (dirty)        
               
        setFormState({...formState,
            isInitializing: false,
            error: false,    
            errorText: "",           
            disabled: false, 

            purgeButtonLoading: false,
            renderButtonLoading: false,

            mainButtonLoading: false,
            mainButtonDisabled: !!(formValid),
            
            dirty: dirty,
            valid: !!(formValid),            
        })        
    }, [formData, formRefresh])

    const handleOnChange = (event) => {
        const id = (event.target.name || event.target.id)
        const value = event.target.value        

        setFormData((prevState) => {return setStateKeyValuePair(prevState, id, value)})       

        return true
    }

    const handleOnClickMainButton = () => {
        if (!formData.id)
            handleCreate()
        else
            handleUpdate() 
    }

    const handleOnPurge = () => {
        setDialogDelete({
            open: true,
            title: "Warning",
            children: (
                <DialogContentText id="alert-dialog-description">
                    You are about to purge all Entities created with this type. Do you want to continue?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
               await handlePurge()
            },
            onDecline: () => {}
        })
    }

    const handleOnRender = () => {
        setDialogDelete({
            open: true,
            title: "Warning",
            children: (
                <DialogContentText id="alert-dialog-description">
                    You are about to render all Entities created with this type. Depending on the amount of data, it could take awhile. Do you want to continue?                    
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
               await handleRender()
            },
            onDecline: () => {}
        })
    }

    const handleCreate = async () => {
        setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        try {            
            let postType = await fetch(API_URL_SIMPLECRM.TYPES, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(formData)
            })

            if (!postType.ok)
                throw new Error((await postType.json()).error.code)
            
            let newType = await postType.json()            

            setFormDefaultData(structuredClone(newType))            
            SimpleUIAppUpdateRoutes(appDispatch, {user: user, accessToken: accessToken, authorized: authorized, layouts: layouts, settings: settings})

            navigate('/types/'+ newType.id)     
        } catch (error) {                      
            handleError(error)
        }        
    }

    const handleUpdate = async () => {
        setFormState({ ...formState, disabled: true, mainButtonLoading: true })

        try {
            let patchType = await fetch(`${API_URL_SIMPLECRM.TYPES}${params.typeId}`, {
                method: 'PATCH', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                body: JSON.stringify(formData)
            })  

            if (!patchType.ok)
                throw new Error((await patchType.json()).error.code)
                        
            setFormDefaultData(structuredClone(formData))
            // SimpleUIAppUpdateRoutes(appDispatch, {user: user, accessToken: accessToken, routeBuilder: routeBuilder})
            SimpleUIAppUpdateRoutes(appDispatch, {user: user, accessToken: accessToken, authorized: authorized, layouts: layouts, settings: settings})
        } catch (error) {
            handleError(error)
        }        
    }

    const handlePurge = async () => {
        setFormState({ ...formState, disabled: true, purgeButtonLoading: true })

        try {
            let fetchGet = await fetch(`${API_URL_SIMPLECRM.TYPES}${params.typeId}/purge`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                
            })  

            if (!fetchGet.ok)
                throw new Error((await fetchGet.json()).error.code)
            
            setFormRefresh(!formRefresh)
        } catch (error) {
            handleError(error)
        }        
    }

    const handleRender = async () => {
        setFormState({ ...formState, disabled: true, renderButtonLoading: true })

        try {
            let fetchGet = await fetch(`${API_URL_SIMPLECRM.TYPES}${params.typeId}/render`, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                },
                
            })  

            if (!fetchGet.ok)
                throw new Error((await fetchGet.json()).error.code)
            
            setFormRefresh(!formRefresh)
        } catch (error) {
            handleError(error)
        }        
    }
    
    const handleOnList = () => {        
    }

    const handleClose = () => {
        navigate('/types/') 
    }
    
    const handleError = (error) => {
        switch (error.message) {
            case "ER_ENTITYTYPE_NOT_FOUND":
                setFormState({ ...formState, error: true, errorText: "EntityType not found." })
                break

            case "ER_ENTITYTYPE_MALFORMED_DATA":
                setFormState({ ...formState, error: true, errorText: "Malformed data." })
                break
            
            case "ER_ENTITYTYPE_REFERENCED_FIELD_NOT_VALID":
                setFormState({ ...formState, error: true, errorText: "Reference error." })
                break

            default:
                setFormState({ ...formState, error: true, errorText: "Unexpected error occured." })
        }
        console.log (error.message)
    }
    // #endregion

    // #region BLOCKER
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            formState.dirty &&
            (formData.id) &&
            currentLocation.pathname !== nextLocation.pathname
    )

    useEffect(() => {
        if (blocker.state === "blocked")
            handleBlocked()
    }, [blocker])

    const handleBlocked = () => {
        setDialogDelete({
            open: true,
            title: "Warning",
            children: (
                <DialogContentText id="alert-dialog-description">
                    There are unsaved changes, would you like to proceed?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
                 blocker.proceed()
            },
            onDecline: () => {
                blocker.reset()
            }
        })
    }
    // #endregion

    // #region DIALOGS
    const [ dialogStateBlocked, setDialogBlocked ] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    const [ dialogStateDelete, setDialogDelete ] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })
    // #endregion
  
    // #region TAB STATES
    const [ tab, setTab ] = React.useState(1)
    const handleOnChangeTab = useCallback((event, newValue) => {
        setTab(newValue)
    }, [])
    // #endregion

    // #region DATAGRIDS
    // const [ selectorOpenAction, setSelectorOpenAction ] = React.useState(false)

    const [ dataGridColumnsFields, setDataGridColumnsFields ] = useState([
        { field: 'label', headerName: 'Label', flex: 0.3 },
        { field: 'type', headerName: 'Type', flex: 0.2 },
        { field: 'required', headerName: 'Required', width: 100,
            renderCell: (params) => {
                const { row } = params
                if (row.required)
                    return "yes"
                else 
                    return "no"
            }
        },
        { field: 'unique', headerName: 'Unique', width: 100,
            renderCell: (params) => {
                const { row } = params
                if (row.unique)
                    return "yes"
                else 
                    return "no"
            }
        },
        { field: 'options', headerName: 'Options', flex: 0.5 },
        { field: 'buttons', headerName: '', sortable: false, align: "right",
            renderCell: (params) => {
                const { row } = params
                const handleEdit = () => {
                    handleEditField(row.id)
                }

                const handleRemove = () => {
                    handleRemoveField(row.id)
                }

                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                    
                        <IconButton aria-label="edit" size="small" onClick={handleEdit}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>
                            
                        <IconButton aria-label="delete" size="small" onClick={handleRemove} disabled={row.hasRef}>
                            <RemoveIcon fontSize="inherit"/>
                        </IconButton>
                    </Stack>
                )
            }           
        },
    ])
    const [ dataGridRowsFields, setDataGridRowsFields ] = useState([])
    const [ dataGridSelectionModelFields, setDataGridSelectionModelFields ] = useState([])

    const handleOnRowSelectionModelChangeFields = (selection) => {
        setDataGridSelectionModelFields(selection)
    }

    const [ dataGridColumnsLayouts, setDataGridColumnsLayouts ] = useState([
        { field: 'name', headerName: 'Name', flex: 0.3 },
        { field: 'type', headerName: 'Type', flex: 0.2 },
        { field: 'default', headerName: 'Default', width: 100,
            renderCell: (params) => {
                const { row } = params
                if (row.default)
                    return "yes"
                else 
                    return "no"
            }
        },        
        { field: 'buttons', headerName: '', flex: 1.0, sortable: false, align: "right",
            renderCell: (params) => {
                const { row } = params
                const handleEdit = () => {
                    handleEditLayout(row.id)
                }

                const handleRemove = () => {
                    handleRemoveLayout(row.id)
                }

                return (
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                    
                        <IconButton aria-label="edit" size="small" onClick={handleEdit}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>
                            
                        <IconButton aria-label="delete" size="small" onClick={handleRemove} disabled={row.hasRef}>
                            <RemoveIcon fontSize="inherit"/>
                        </IconButton>
                    </Stack>
                )
            }           
        },
    ])
    const [ dataGridRowsLayouts, setDataGridRowsLayouts ] = useState([])
    const [ dataGridSelectionModelLayouts, setDataGridSelectionModelLayouts ] = useState([])

    const dataGridGetRowId = (row) => {
        return row.id
    }

    const handleOnRowSelectionModelChangeLayouts = (selection) => {
        setDataGridSelectionModelLayouts(selection)
    }
    // #endregion

    // #region FIELD ADD/EDIT
    const [ drawerFieldAddState, setDrawerFieldAddState ] = useState({ open: false })
    const [ drawerFieldEditState, setDrawerFieldEditState ] = useState({ open: false })

    const handleAddField = async () => {
        setDrawerFieldAddState({...drawerFieldAddState, open: true})
    }

    const handleEditField = async () => {
        setDrawerFieldEditState({...drawerFieldEditState, open: true})
    }

    const handleRemoveField = async (id) => {
        setDialogDelete({
            open: true,
            title: "Delete field",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Deleting a field will result in removal of data from entitys created with this type. Do you want to continue?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {
                setFormData(prevState => {
                    let newState = structuredClone(prevState)
                    delete newState.fields[id]
                    return newState
                })        
            },
            onDecline: () => {}
        })
    }

    const handleCallbackEditField = (callbackResult) => {
        const { id, ...newField } = callbackResult               

        if (id) {
            setFormData(prevState => {
                let newState = structuredClone(prevState)
                newState.fields[id] = newField
                return newState
            })
        } else {
            setFormData(prevState => {
                let newState = structuredClone(prevState)
                if (!newState.fields)
                    newState.fields = {}
                newState.fields[crypto.randomUUID()] = newField
                return newState
            })
        }
    }
    // #endregion    

        // #region LAYOUT ADD/EDIT
        const [ drawerLayoutAddState, setDrawerLayoutAddState ] = useState({ open: false })
        const [ drawerLayoutEditState, setDrawerLayoutEditState ] = useState({ open: false })
    
        const handleAddLayout = async () => {
            setDrawerLayoutAddState({...drawerFieldAddState, open: true})
        }
    
        const handleEditLayout = async () => {
            setDrawerLayoutEditState({...drawerFieldEditState, open: true})
        }
    
        const handleRemoveLayout = async (id) => {
            // setDialogDelete({
            //     open: true,
            //     title: "Delete layout",
            //     children: (
            //         <DialogContentText id="alert-dialog-description">
            //             Deleting a field will result in removal of data from entitys created with this type. Do you want to continue?
            //         </DialogContentText>),
            //     button1Text: "No",
            //     button2Text: "Yes",
            //     onConfirm: async () => {
            //         setFormData(prevState => {
            //             let newState = structuredClone(prevState)
            //             delete newState.layouts[id]
            //             return newState
            //         })        
            //     },
            //     onDecline: () => {}
            // })
        }
    
        const handleCallbackEditLayout = (callbackResult) => {
            const newLayout = callbackResult               
                
            if (newLayout.id) {                
                setFormData(prevState => {
                    let newState = structuredClone(prevState)
                    newState.layouts[newState.layouts.map(function(e) { return e.id; }).indexOf(newLayout.id)] = newLayout
                    return newState
                })
            } else {
                setFormData(prevState => {
                    let newState = structuredClone(prevState)
                    if (!newState.layouts)
                        newState.layouts = []

                    newLayout.id = crypto.randomUUID()
                    newState.layouts.push(newLayout) 
                    return newState
                })
            }
        }
        // #endregion   
            
        const getEntityTypes = async () => {
            let output = []    
            try {
                let getTypes = await fetch(API_URL_SIMPLECRM.TYPES, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           
    
                if (!getTypes.ok)
                    throw new Error((await getTypes.json()).error.code)
    
                output = await getTypes.json()
            } catch (error) {            
                handleError(error)
            }  
    
            return output
        }

        const getStats = async () => {
            let output = {}
            try {
                let fetchGet = await fetch(`${API_URL_SIMPLECRM.TYPES}${params.typeId}/stats`, {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer "+ accessToken
                    }            
                })           
    
                if (!fetchGet.ok)
                    throw new Error((await fetchGet.json()).error.code)
    
                output = await fetchGet.json()
            } catch (error) {            
                handleError(error)
            }  
    
            return output
        }

    if (formState.isInitializing) {
        return (
            <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <CircularProgress  variant="indeterminate" style={{width: '50px', height: '50px'}}/>
            </Box>                  
        ) 
    } 
    
    return (
        <React.Fragment>
            <SimpleUIDialogConfirm dialogState={dialogStateBlocked} setDialogState={setDialogBlocked}/>
            <SimpleUIDialogConfirm dialogState={dialogStateDelete} setDialogState={setDialogDelete}/>

            <SimpleUIDialogConfirm dialogState={dialogState} setDialogState={setDialog}/>

            <SimpleUIDrawer state={drawerFieldAddState} setState={setDrawerFieldAddState} type={formData} callback={handleCallbackEditField}>
                <FormField/>
            </SimpleUIDrawer>
            <SimpleUIDrawer state={drawerFieldEditState} setState={setDrawerFieldEditState} fieldId={dataGridSelectionModelFields} type={formData} callback={handleCallbackEditField}>
                <FormField/>
            </SimpleUIDrawer>

            <SimpleUIDrawer state={drawerLayoutAddState} setState={setDrawerLayoutAddState} type={formData} callback={handleCallbackEditLayout}>
                <FormLayout/>
            </SimpleUIDrawer>
            <SimpleUIDrawer state={drawerLayoutEditState} setState={setDrawerLayoutEditState} layoutId={dataGridSelectionModelLayouts} type={formData} callback={handleCallbackEditLayout}>
                <FormLayout/>
            </SimpleUIDrawer>
                        
            <Paper sx={{p: 1 , top: "0px", position:"sticky", zIndex: 10}} >
                <Stack spacing={2} direction="row" justifyContent="end">
                        <Button variant="contained" color="success" disabled={formState.disabled || !formData.id || formData.parent !== null} onClick={handleOnList}>
                            List Entities ({stats.entities})
                        </Button>

                        <LoadingButton variant="contained" color="warning" loading={formState.renderButtonLoading} disabled={formState.disabled || !formData.id || formData.parent !== null} onClick={handleOnRender}>
                            Render Entities ({stats.entities})
                        </LoadingButton>

                        <LoadingButton variant="contained" color="error" loading={formState.purgeButtonLoading} disabled={formState.disabled || !formData.id || formData.parent !== null} onClick={handleOnPurge}>
                            Purge Entities ({stats.entities})
                        </LoadingButton>                        

                        <LoadingButton variant="contained" color="primary" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleOnClickMainButton}>
                            {(!formData.id) ? "Create" : "Save" }
                        </LoadingButton>  
                        
                        <Button variant="outlined"  aria-label="close" onClick={handleClose} disabled={formState.disabled}>
                            Close
                        </Button>
                </Stack>

                <Collapse in={formState.error}>
                    <Alert variant="filled" severity="error" sx={{mb: 2}}
                        action={
                            <IconButton aria-label="close" color="inherit" size="small"
                                onClick={() => {
                                    setFormState({ ...formState, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formState.errorText}
                    </Alert>
                </Collapse>
            </Paper>
            
            <Grid container>
                <Grid item md={12}>
                    <Paper sx={sxPaper}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant='h6'>Details</Typography>
                            </Grid>
                            <Grid xs={12}>
                                {formData.id && (
                                    <TextField
                                        margin="dense"                    
                                        label="Id"
                                        fullWidth                    
                                        value={formData.id ?? ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}                                                       
                                    />
                                )}
                                <TextField
                                    id={"name"}

                                    label={"Name"}

                                    value={formData.name}
                                    onChange={handleOnChange}
                                
                                    required={true}
                                    
                                    variant="outlined"
                                    margin="dense"                        
                                    fullWidth={true}
                                    autoComplete="off"
                                    
                                    inputProps={{ maxLength: 50}}
                                    
                                    disabled={(formState.locked || formState.disabled)}
                                />
                                <FormControl sx={{mt: 1, mb: 1}}
                                    fullWidth 
                                >
                                    <InputLabel>ParentType</InputLabel>
                                    <Select                                  
                                        label="ParentType"
                                        name="parent"                                        
                                        value={formData?.parent ?? ""}
                                        onChange={handleOnChange}                        
                                        disabled={(          
                                            (formData.id) ||                                  
                                            (formState.disabled)
                                        )}
                                    >       
                                        <MenuItem key={0} value={""}>{"-- No Parent --"}</MenuItem>

                                        {entityTypes.map(function (item, i)  {                                    
                                            return (
                                                <MenuItem key={i++} value={item.id}>{item.name}</MenuItem>
                                            )
                                        })}                                        
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>                        
                    
                </Grid>

                <Grid xs={12}>
                    <Paper sx={sxPaper}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant='h6'>Fields</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <DataGrid
                                    autoHeight
                                    hideFooter
                                    columns={dataGridColumnsFields} 
                                    rows={dataGridRowsFields}
                                    onRowSelectionModelChange={handleOnRowSelectionModelChangeFields}
                                    rowsPerPageOptions={[100]} 
                                    selectionModel={dataGridSelectionModelFields}
                                    getRowId={dataGridGetRowId}                        
                                />
                                <Button onClick={handleAddField}>Add field</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid xs={12}>
                    <Paper sx={sxPaper}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant='h6'>Navigation</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    id={"navigation.url"}

                                    label={"URL"}

                                    value={formData.navigation?.url}
                                    onChange={handleOnChange}
                                
                                    // required={true}
                                    
                                    variant="outlined"
                                    margin="dense"                        
                                    fullWidth={true}
                                    autoComplete="off"
                                    
                                    inputProps={{ maxLength: 50}}
                                    
                                    disabled={(formState.locked || formState.disabled || formData.parent)}                    
                                />
                            </Grid>
                            <Grid xs={4}>
                                <TextField
                                    id={"navigation.label"}

                                    label={"Label"}

                                    value={formData.navigation?.label}
                                    onChange={handleOnChange}
                                
                                    // required={true}
                                    
                                    variant="outlined"
                                    margin="dense"                        
                                    fullWidth={true}
                                    autoComplete="off"
                                    
                                    inputProps={{ maxLength: 50}}
                                    
                                    disabled={(formState.locked || formState.disabled || formData.parent)}                    
                                />
                            </Grid>
                            <Grid xs={4}>
                                <TextField
                                    id={"navigation.icon"}

                                    label={"Icon"}

                                    value={formData.navigation?.icon}
                                    onChange={handleOnChange}
                                
                                    variant="outlined"
                                    margin="dense"                        
                                    fullWidth={true}
                                    autoComplete="off"                                                
                                    
                                    disabled={(formState.locked || formState.disabled || formData.parent)}                    
                                />
                            </Grid>
                            <Grid xs={4}>
                                <TextField
                                    id={"navigation.sort"}

                                    label={"Sort"}

                                    value={formData.navigation?.sort}
                                    onChange={handleOnChange}
                                
                                    variant="outlined"
                                    margin="dense"                        
                                    fullWidth={true}
                                    autoComplete="off"                                                
                                    
                                    disabled={(formState.locked || formState.disabled || formData.parent)}                    
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid xs={12}>
                    <Paper sx={sxPaper}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Typography variant='h6'>Layouts</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <DataGrid
                                    autoHeight
                                    hideFooter
                                    columns={dataGridColumnsLayouts} 
                                    rows={dataGridRowsLayouts}
                                    onRowSelectionModelChange={handleOnRowSelectionModelChangeLayouts}
                                    rowsPerPageOptions={[100]} 
                                    selectionModel={dataGridSelectionModelLayouts}
                                    getRowId={dataGridGetRowId}                        
                                />
                                <Button onClick={handleAddLayout}>Add layout</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
                
        </React.Fragment>
    )
}

